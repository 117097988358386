<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          <v-row class="align-center">
            <v-col cols="12" sm="6" md="3">
              <year-picker clearable :label="$t('global.year')" v-model="date" />
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <document-type-picker v-model="table.criteria.type" clearable />
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <document-category-picker v-model="table.criteria.category" clearable />
            </v-col>
            <v-col cols="12" sm="6" md="3" class="d-flex justify-end">
              <v-spacer />
              <id-button @click="onRefreshData">
                <template #icon>
                  <v-icon left>mdi-refresh</v-icon>
                </template>
                {{ $t("buttons.refresh") }}
              </id-button>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="table.headers"
          :items="table.data"
          :options.sync="table.options"
          :server-items-length="table.totalItems"
          single-select
          :loading="table.loading"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
          class="elevation-1"
        >
          <template slot="item" slot-scope="row">
            <tr :active="row.selected">
              <td>
                <employee-profile :employee="row.item.sender" />
              </td>
              <td>
                {{ row.item.name }}
              </td>
              <td>{{ row.item.type.name }}</td>
              <td>{{ $helpers.get(row.item, "category.name") }}</td>
              <td>{{ row.item.createdDateTime | formattedDate }}</td>
              <td>
                <file-download-button :url="row.item.publicURL" />
              </td>
            </tr>
          </template>
        </v-data-table>
        <transition name="slide-in-left">
          <router-view @success:save="onRefreshData" />
        </transition>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  /**
   * @project personal-idenfit
   * @developer Halil Kılıçarslan
   * @description Performance Component Logic
   * @date 28.07.2020
   */

  import { DOCUMENTS } from "../query";

  export default {
    name: "Documents",
    components: {
      YearPicker: () => import("@/components/selectpickers/YearPicker"),
      DocumentCategoryPicker: () => import("./components/document-category-picker"),
      DocumentTypePicker: () => import("./components/document-type-picker")
    },
    data: (vm) => ({
      beginDateMenu: false,
      date: null,
      table: {
        loading: false,
        data: [],
        criteria: {
          sender: vm.$store.state.auth.user.id,
          beginDate: null,
          endDate: null,
          type: null,
          category: null
        },
        options: {
          page: 1,
          itemsPerPage: 10,
          sortBy: ["name"],
          sortDesc: [true]
        },
        totalItems: 0,
        totalPages: 0,
        selected: [],
        headers: [
          {
            text: vm.$t("hr.document.sender"),
            value: "sender.firstName",
            align: "left",
            sortable: true,
            class: ["primary--text"],
            width: "10%"
          },
          {
            text: vm.$t("hr.document.name"),
            value: "name",
            align: "left",
            sortable: true,
            class: ["primary--text"],
            width: "15%"
          },
          {
            text: vm.$t("hr.document.type"),
            value: "type.name",
            align: "left",
            sortable: true,
            class: ["primary--text"],
            width: "30%"
          },
          {
            text: vm.$t("hr.document.category"),
            value: "category.name",
            align: "left",
            sortable: true,
            class: ["primary--text"],
            width: "30%"
          },
          {
            text: vm.$t("hr.document.date"),
            value: "createdDateTime",
            align: "left",
            sortable: true,
            class: ["primary--text"],
            width: "10%"
          },
          {
            text: "",
            align: "left",
            sortable: false,
            value: "url",
            class: ["primary--text"],
            width: "5%"
          }
        ]
      }
    }),
    watch: {
      date: {
        handler(v) {
          if (v) {
            this.table.criteria.beginDate = this.$moment(v.toString()).startOf("year").format("YYYY-MM-DD");
            this.table.criteria.endDate = this.$moment(v.toString()).endOf("year").format("YYYY-MM-DD");
          } else {
            this.table.criteria.beginDate = null;
            this.table.criteria.endDate = null;
          }
        },
        deep: true
      },
      "table.options": {
        handler() {
          this.fetchItems();
        },
        deep: true
      },
      "table.criteria": {
        handler() {
          this.fetchItems();
        },
        deep: true
      }
    },
    computed: {
      formattedMonth() {
        return this.$moment(this.date).format("YYYY");
      }
    },
    methods: {
      onRefreshData() {
        this.fetchItems();
      },
      async fetchItems() {
        this.table.loading = true;
        await this.$apollo
          .query({
            query: DOCUMENTS,
            variables: {
              criteria: this.table.criteria,
              pagination: this.$helpers.toPaginationCriteria(this.table.options)
            }
          })
          .then(({ data, errors }) => {
            if (!errors || !data.error) {
              this.table.data = data.filterWithPaginateDocuments.content || [];
              this.table.totalItems = data.filterWithPaginateDocuments.pageInfo.totalElements || 0;
            }
          })
          .catch((e) => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => (this.table.loading = false));
      },
      onCreate() {
        this.$router.push({ name: "document_create" });
      }
    }
  };
</script>

<style scoped></style>
